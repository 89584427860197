.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);

}

.modal-main {
    position: fixed;
    background: transparent;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    padding: 20px;
    transform: translate(-50%, -50%);
    border-radius: 10px;
}

/*.modal-main1 {*/
/*    position: fixed;*/
/*    background: transparent;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    padding: 20px;*/
/*    transform: translate(-50%, -50%);*/
/*    border-radius: 10px;*/
/*}*/

.display-block {
    display: block;
    transition: ease-in;
    -webkit-transition: ease-in;
    -moz-transition: ease-in;
    -ms-transition: ease-in;
    -o-transition: ease-in;
}

.display-none {
    display: none;
    /*transition: ease-in-out(15);*/
    /*-webkit-transition: ease-in-out(15);*/
    /*-moz-transition: ease-in-out(15);*/
    /*-ms-transition: ease-in-out(15);*/
    /*-o-transition: ease-in-out(15);*/
}

.titulo_modal {
    color: #c2a64f;
    font-size: 20px;
}

.button_div {
    width: 100% !important;
}

.btn-calendar-cliente1 {
    background-color: #c2a64f !important;
    color: #FFF;
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: calc(0.767rem - 1px);
    padding-bottom: calc(0.767rem - 1px);
}

.btn-calendar-cliente1:hover, .btn-calendar-cliente.hover {
    background-color: #977c2b !important;
    color: #FFF !important;
}

@media (max-width: 768px) {
    .btn-calendar-cliente1 {
        background-color: #c2a64f !important;
        color: #FFF;
        font-size: 14px;
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-top: calc(0.767rem);
        padding-bottom: calc(0.767rem);
      }
}

.btn-outline-calendar-cliente1 {
    border: 1px solid #c2a64f;
    background-color: transparent;
    color: #c2a64f !important;
}

.btn-outline-calendar-cliente1:hover, .btn-outline-calendar-cliente1.hover {
    background-color: #977c2b !important;
    color: #FFF !important;
}

.btn-outline-calendar-cliente1:active, .btn-outline-calendar-cliente1.active {
    background-color: #c2a64f !important;
    color: #FFF !important;
}