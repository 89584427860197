.whatsapp-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #ffdb5e;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    animation: breathe 2s ease-in-out infinite;
  }

  .whatsapp-btn:hover {
    background-color: #e7c446;
  }

  /*Estilos solo al icono whatsapp*/
  .whatsapp-btn i {
    color: #fff;
    font-size: 24px;
    animation: beat 2s ease-in-out infinite;
    text-decoration: none;
  }
  
  /*Estilos con animation contorno respirando*/
  @keyframes breathe {
    0% {
      box-shadow: 0 0 0 0 #ffdb5e;
    }
    70% {
      box-shadow: 0 0 0 15px rgba(37, 211, 102, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
  
  /*Estilos de animacion del icono latiendo*/
  @keyframes beat {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }